<template>
  <b-card
    class="banner-edit-wrapper"
  >
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <!-- Title -->
        <b-col md="6">
          <b-form-group
            label="Title"
            label-for="banner-edit-title"
            class="mb-2"
          >
            <b-form-input
              id="banner-edit-title"
              v-model="banner.title"
            />
          </b-form-group>
        </b-col>

        <!-- Status -->
        <b-col md="6">
          <b-form-group
            label="Status"
            label-for="banner-edit-category"
            class="mb-2"
          >
            <v-select
              id="banner-edit-category"
              v-model="banner.is_active ? statusOption[0] : statusOption[1]"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOption"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Featured Image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  :src="banner.image_path"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted">Required image resolution 800x400, image size 10mb.</small>
                <b-card-text class="my-50">
                  <b-link id="banner-image-text">
                    {{ bannerFile ? bannerFile.name : 'banner.jpg' }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="bannerFile"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
          >
            Save Changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :to="{ name: 'banner-manager' }"
            variant="outline-secondary"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  components: {
    BCard,
    BMedia,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bannerFile: null,
      categoryOption: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
      statusOption: ['Active', 'Inactive'],
      snowOption: {
        theme: 'snow',
      },
    }
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })
    const banner = ref(null)
    store.dispatch('banner/fetchBanner', { id: router.currentRoute.params.id })
      .then(response => {
        banner.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          banner.value = undefined
        }
      })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      banner,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
